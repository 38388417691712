<script>

    let dbg = true;

    const DEVMODE = import.meta.env.DEV;
    if (!DEVMODE) dbg=false;

    import { page } from "$app/stores";
    let _key = $page.url.pathname;

	export let data;

    if (!dbg) {
        dbg=data.dbg;
    }

    $: dbg&&console.group('%c'+_key, 'color:magenta');
    $: dbg&&console.log('%c'+ JSON.stringify($page.params,null,2), 'color:magenta');
    $: dbg&&console.log('%c'+ JSON.stringify($page.url,null,2), 'color:magenta');
    $: dbg&&console.log('data', data);


	// //====================================================================================
	// //  dbg
	// //====================================================================================

    // let dbg = false;

    // const DEVMODE = import.meta.env.DEV;
    // if (!DEVMODE) dbg=false;

    // import { page } from "$app/stores";
    // const _key = $page.route.id;
    // $: dbg&&console.group('%c'+_key,'color:magenta');

	//====================================================================================
	//
	//====================================================================================

    import * as d3 from 'd3';
    import d3comparator from "$lib/d3comparator.js";
    const   f1 = d3.format('.1f'),
            f2 = d3.format('.2f');

	//=====================
	//  moment
	//=====================
	import moment from 'moment';
	moment.locale('ms');
	moment.updateLocale('ms',{
	  months : ["Januari", "Februari", "Mac", "April", "Mei", "Jun", "Julai","Ogos", "September", "Oktober", "November", "Disember"],
	  monthsShort : ["Jan", "Feb", "Mac", "Apr", "Mei", "Jun", "Jul", "Ogo", "Sep", "Okt", "Nov", "Dis" ],
	  meridiem: function(hour, minute, isLowerCase)	{ return hour < 12 ? 'pagi' : hour==12 ? 'tengahari' : hour < 20 ? 'petang' : 'malam';},
	  weekdays : ["Ahad", "Isnin", "Selasa", "Rabu", "Khamis", "Jumaat", "Sabtu"]
	});




	//====================================================================================
	//
	//====================================================================================

    let width;
    let height;




	//====================================================================================
	// perubahanLokasi
	//====================================================================================

    let sortLokasi = {
        by: 'purata_negara_perc_diff', // purata_negara_perc_diff || avg
        order: 'asc'
    };

    $: perubahanLokasi = data['weekly_prev_perc_diff_negeri']
                            .map(d=>{
                                d.url = urlNegeri(d.negeri, d.stateid);
                                d.label = d.negeri;
                                return d;
                            })
                            .sort(d3comparator().order( sortLokasi.order=='asc'? d3.ascending : d3.descending, d=>d[ sortLokasi.by ] ))

    $: dbg&&console.log('perubahanLokasi', perubahanLokasi);

    $: perubahanLokasiMax = d3.max(perubahanLokasi, d=>d.avg);
    $: perubahanLokasiMin = d3.min(perubahanLokasi, d=>d.avg);

    $: dbg&&console.log('perubahanLokasiMax', perubahanLokasiMax);
    $: dbg&&console.log('perubahanLokasiMin', perubahanLokasiMin);


    $: perubahanLokasiMaxNegeri = perubahanLokasi.filter(d=>d.avg==perubahanLokasiMax);
    $: perubahanLokasiMinNegeri = perubahanLokasi.filter(d=>d.avg==perubahanLokasiMin);

    $: dbg&&console.log('perubahanLokasiMaxNegeri', perubahanLokasiMaxNegeri);
    $: dbg&&console.log('perubahanLokasiMinNegeri', perubahanLokasiMinNegeri);

	//====================================================================================
	// perubahanBarang
	//====================================================================================

    let sortBarang = {
        by: 'avg', // avg
        order: 'asc'
    };

    $: perubahanBarang = data['weekly_prev_perc_diff_group']
                            .map(d=>{
                                d.url = urlGroup(d.key);
                                d.label = d.key;
                                return d;
                            })
                            .sort(d3comparator().order( sortBarang.order=='asc'? d3.ascending : d3.descending, d=>d[ sortBarang.by ]||0 ))



	//====================================================================================
	// color scale
	//====================================================================================

    // $: minMaxPurata     = d3.extent( data['harga_purata'], d=>d.avg );

    // $: min = d3.min([minMaxPurata[0], 0]);
    // $: max = d3.max([minMaxPurata[1], 0]);

    // $: _scale = d3.scaleLinear().domain([min, data.avg, max]).range([1,.5,0]);
    // $: _scale1 = d3.scaleLinear().domain([min, data.avg]).range([1,.6]);
    // $: _scale2 = d3.scaleLinear().domain([data.avg, max]).range([.6,0]);



    // const color = d3.scaleSequential(d3.interpolateRdYlGn);

	//====================================================================================
	// _internalLinks
	//====================================================================================

    const _internalLinks = [
        {href:'#perubahan_harga', label:'Peratus perubahan harga'},
        {href:'#perubahan_harga_mengikut_negeri', label:'Harga turun/naik mengikut negeri, daerah'},
        {href:"#perubahan_harga_mengikut_kumpulan_barang", label:"Harga turun/naik mengikut kategori barang"},
        {href:"#kedai_harga_murah", label:"Kedai harga murah"},
        {href:"#harga_barang_paling_rendah", label:"Harga purata barangan"},
    ];


	//====================================================================================
	// carbon-components-svelte
	//====================================================================================

    import {
        Breakpoint,
    } from "carbon-components-svelte";

    let size='sm';
    let events = [];

	//====================================================================================
	// components
	//====================================================================================

    import {
        Config
    } from "$lib/Config.svelte";

    import {
        urlDebug,
        labelDateRange,
        urlNegeri,
        urlGroup,
        urlBarang,
        urlKedai,
    } from "$lib/components/Utilities.svelte"


    import {
        ButtonColor
    } from "$lib/components/PerubahanHarga/";


    import {
        KedaiMurah
    } from "$lib/components/KedaiMurah/"
import KedaiMurahSkeleton from "$lib/components/KedaiMurahSkeleton.svelte"

    import {
        // HargaPalingRendah,
        HargaPurata
    } from "$lib/components/HargaPalingRendah/"
    
import HargaPalingRendahSkeleton from "$lib/components/HargaPalingRendahSkeleton.svelte"
import TableSkeleton from "$lib/components/TableSkeleton.svelte"

    // Affiliates section removed
    // Pageview removed


    import * as Card from "$lib/components/ui/card"
    import * as Table from "$lib/components/ui/table";


    function capitalizeText(text) {
        return text.split(/\s+|\./)
                .map(d=>d.charAt(0).toUpperCase() + d.slice(1).toLowerCase())
                .join(' ');
    }

    $: dbg&&console.groupEnd(_key);

</script>

<svelte:window  bind:innerWidth={width} bind:innerHeight={height} />


<Breakpoint bind:size on:change={(e) => (events = [...events, e.detail])} />



<svelte:head>

	<title>Mana Murah: Mana kedai murah, harga murah, barang turun harga di Malaysia</title>

    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="language" content="ms">
    <meta name="title" content="Mana Murah: Harga terkini, harga murah, kedai murah di Malaysia">
    <meta name="description" content="Perbandingan harga terkini paling murah di seluruh Malaysia mengikut Negeri dan Daerah. Harga terkini setiap minggu. Bandingkan kedai harga murah. Manamurah.com is a valuable resource for households shopping in Malaysian markets, especially for those keen on finding the best prices. It updates weekly on average price changes for various goods, notably fresh and packaged items, across different regions. The site lists stores offering goods below average prices, aiding in budgeting and planning for grocery shopping. It's particularly useful for finding cost-effective options in fresh produce like vegetables and fruits">
    <meta name="keywords" content="Malaysia, MY, harga terkini, harga murah, belanja murah, banding harga, jimat, harga barang, harga, murah, kedai, pasaraya, supermarket, mini market, pasar, runcit, barang, kedai murah, barang murah, mana murah, harga turun, harga barang, ayam, ikan, sotong, udang, daging, lembu, kerbau, kambing, kelapa, santan, telur, cili, cili padi, bawang, keli, tilapia, haruan, kembung, selar, ikan merah, jenahak, kerapu, mabung, senangin, ikan parang, bawal, cencaru, gelama, pari, tamban, tenggiri, terubok, kerang, ketam">
    <meta name="robots" content="index, follow">
    <meta name="revisit-after" content="7 days">
    <meta property="og:title" content="Mana Murah: Mana kedai murah, harga murah, barang turun harga di Malaysia">
    <meta property="og:description" content="Perbandingan harga paling murah di seluruh Malaysia mengikut Negeri dan Daerah. Harga terkini setiap minggu. Bandingkan harga kedai.">
    <meta property="og:type" content="website">
    <meta property="og:url" content="https://manamurah.com">
    <meta property="og:image" content="https://image.social/get?url=manamurah.com">

</svelte:head>


<!--
    barang images
    - data.items_avg
-->

<Card.Content class="px-8 pb-8 bg-[#427FED]">
    {#if size=='sm'}
        <div class="flex flex-row-reverse justify-center items-end pb-8 pt-8  overflow-hidden">
            {#each d3.shuffle(data._items_avg).slice(0,12).map(d=>d.lookup_item) as d}
                <a href={urlBarang(d.item, d.item_code)}
                        class="group hover:scale-110"
                        style="
                            width:120px;
                            height:120px;
                            margin-left:-30px;
                            margin-right:-30px;
                            margin-top:-30px;
                            margin-bottom:-30px;                        "
                >
                    <img src="https://img.manamurah.com/barang_nobg/{d.item_code}.png"
                        alt={d.item}
                        style="
                            width:120px;
                            height:120px;

                        "/>
                </a>
            {/each}
        </div>
    {:else}
        <div class="flex flex-row-reverse justify-center items-end pb-8 pt-16 overflow-hidden">
            {#each d3.shuffle(data._items_avg).slice(0,12).map(d=>d.lookup_item) as d}
                <a href={urlBarang(d.item, d.item_code)}
                        class="group hover:scale-110"
                        style="
                            width:200px;
                            height:200px;
                            margin-left:-48px;
                            margin-right:-48px;
                            margin-top:-48px;
                            margin-bottom:-48px;
                        "
                >
                    <img src="https://img.manamurah.com/barang_nobg/{d.item_code}.png"
                        alt={d.item}
                        style="
                            width:200px;
                            height:200px;
                        "/>
                </a>
            {/each}
        </div>
    {/if}
</Card.Content>


<!--
    page header
-->

<div class="flex items-stretch flex-wrap gap-1 p-1 pb-0 mt-[-80px]">

    <Card.Root class="flex-1 shadow-sm pt-4">

        <Card.Header style="margin-top:-24px">


            <!--
                h1
            -->
            <Card.Title tag="h1" class="z-40">

                Mana murah?

            </Card.Title>
            <Card.Title tag="h2" class="mb-4 z-40 text-center py-4">

                <span class="whitespace-nowrap">Kedai murah</span> dan
                <span class="whitespace-nowrap">harga barang murah</span> untuk
                {@html labelDateRange( data.maxWeekDate, data.maxDate) }




            </Card.Title>


            <!--
                internal links
            -->

            <!-- <Card.Description>
                <div class="flex items-stretch flex-wrap gap-1 p-0">
                    {#each _internalLinks as d}
                        <a href={d.href} class="group flex-1 ">
                            <Card.Root class=" bg-[#427FED] text-white shadow-none h-full group-hover:bg-[purple] py-2">
                                <Card.Header class="p-2">
                                    <Card.Title tag="h7" class="pl-4 text-white">
                                        <span class="ml-[-12px] font-thin text-md">#</span>
                                        <span class="leading-6 text-lg font-normal group-hover:underline">
                                            {d.label}
                                        </span>
                                    </Card.Title>
                                </Card.Header>
                            </Card.Root>
                        </a>
                    {/each}
                </div>
            </Card.Description>
 -->


            <Card.Description class="py-4">

                <!-- The latest price trends in Malaysia show an average increase of 0.3% for various goods. Regions like Negeri Sembilan, Perak, and Johor are seeing prices below the national average, while areas like Sabah, Sarawak, and Kuala Lumpur are experiencing higher price increases. Categories like fresh goods and dry items have seen significant price changes. For specific details on goods and regional price changes, refer to the site's weekly updates .

                <p class="py-4">
                Tren harga terkini di Malaysia menunjukkan peningkatan purata sebanyak 0.3% untuk pelbagai barangan. Wilayah seperti Negeri Sembilan, Perak, dan Johor menunjukkan harga di bawah purata nasional, sementara Sabah, Sarawak, dan Kuala Lumpur mengalami peningkatan harga yang lebih tinggi. Untuk butiran spesifik tentang barang dan perubahan harga wilayah, sila rujuk kemas kini mingguan di laman web.

                </p> -->

<!--
                {#each data['weekly_prev_perc_diff'] as d}
                    {#if +f1(d.avg*100)==0 }
                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan tiada perubahan purata harga barang seluruh negara.
                    {:else if d.avg>0 }
                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan
                        peningkatan purata harga barang sebanyak
                        +{f1(d.avg * 100)}%
                        untuk pelbagai barangan.
                    {:else}
                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan
                        penurunan purata harga barang sebanyak
                        {f1(d.avg * 100)}%
                        untuk pelbagai barangan.
                    {/if}
                {/each} -->


<!--
                {#if !perubahanLokasi.filter(d=>+f1(d.avg*100) <= 0).length}

                    Kesemua negeri mengalami
                    {d3.shuffle(['peningkatan','kenaikan'])[0]}
                    harga purata

                    {#if perubahanLokasiMaxNegeri.length}
                        dengan
                        <span class="capitalize" style="text-transform: capitalize;">
                            {#if perubahanLokasiMaxNegeri.length > 1}
                                {perubahanLokasiMaxNegeri.slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                                dan {perubahanLokasiMaxNegeri.slice(-1).map(d=>capitalizeText(d.label))}
                            {:else}
                                {perubahanLokasiMaxNegeri.map(d=>capitalizeText(d.label))}
                            {/if}
                        </span>
                        mengalami
                        {d3.shuffle(['peningkatan','kenaikan'])[0]}
                        {d3.shuffle(['paling ketara ','paling besar','paling tinggi','paling banyak'])[0]}
                        sehingga +{f1(perubahanLokasiMax*100)}%.

                    {/if}

                {:else if !perubahanLokasi.filter(d=>+f1(d.avg*100) >= 0).length}


                    Kesemua negeri mengalami
                    {d3.shuffle(['penurunan','pengurangan'])[0]}
                    harga purata

                    {#if perubahanLokasiMinNegeri.length}
                        dengan
                        <span class="capitalize" style="text-transform: capitalize;">
                            {#if perubahanLokasiMinNegeri.length > 1}
                                {perubahanLokasiMinNegeri.slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                                dan {perubahanLokasiMinNegeri.slice(-1).map(d=>capitalizeText(d.label))}
                            {:else}
                                {perubahanLokasiMinNegeri.map(d=>capitalizeText(d.label))}
                            {/if}
                        </span>
                        mengalami
                        {d3.shuffle(['penurunan','pengurangan'])[0]}
                        {d3.shuffle(['paling ketara ','paling besar','paling banyak'])[0]}
                        sehingga +{f1(perubahanLokasiMax*100)}%.

                    {/if}


                {/if}
 -->


                {#each data['weekly_prev_perc_diff'] as d}

                    {@const perubahanBarangNaik= perubahanBarang.filter(d=>d.avg>0)}
                    {@const perubahanBarangTurun = perubahanBarang.filter(d=>d.avg<0)}


                    <!-- -------------------------------------------------------
                        harga purata negara kekal
                    ------------------------------------------------------- -->
                    {#if +f1(d.avg*100)==0 }

                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan tiada perubahan purata harga barang seluruh negara.

                    <!-- -------------------------------------------------------
                        harga purata negara naik
                    ------------------------------------------------------- -->
                    {:else if d.avg*100>0 }

                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan
                        <span class="text-red font-semibold" style="color:{Config.colors.red}">peningkatan</span> purata harga barang sebanyak
                        <span class="text-red font-semibold" style="color:{Config.colors.red}">+{f1(d.avg * 100)}%</span>
                        untuk pelbagai barangan.


                        <!--
                            negeri
                        -->

                        {#if perubahanLokasiMaxNegeri.length}
                            <span class="capitalize" style="text-transform: capitalize;">
                                {#if perubahanLokasiMaxNegeri.length > 1}
                                    {perubahanLokasiMaxNegeri.slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                                    dan {perubahanLokasiMaxNegeri.slice(-1).map(d=>capitalizeText(d.label))}
                                {:else}
                                    {perubahanLokasiMaxNegeri.map(d=>capitalizeText(d.label))}
                                {/if}
                            </span>
                            mengalami
                            {d3.shuffle(['peningkatan','kenaikan'])[0]}
                            {d3.shuffle(['paling ketara ','paling besar','paling tinggi','paling banyak'])[0]}
                            sehingga +{f1(perubahanLokasiMax*100)}%.

                        {/if}



                        <!--
                            group
                        -->
                        {#if perubahanBarangNaik.length}

                            Kategori barang seperti

                            {#if perubahanBarangNaik.length>1}
                                <span class="capitalize" style="text-transform: lowercase;">
                                    {perubahanBarangNaik.sort(d3comparator().order(d3.descending,d=>d['avg'] )).slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')},
                                </span>
                                    dan
                                <span class="capitalize" style="text-transform: lowercase;">
                                    {perubahanBarangNaik.sort(d3comparator().order(d3.descending,d=>d['avg'] )).slice(-1).map(d=>capitalizeText(d.label))}
                                </span>
                            {:else}
                                <span class="capitalize" style="text-transform: lowercase;">
                                    {perubahanBarangNaik.map(d=>capitalizeText(d.label))}
                                </span>
                            {/if}

                            menyumbang kepada kebanyakan peningkatan harga barang.

                        {/if}




                    <!-- -------------------------------------------------------
                        harga purata negara turun
                    ------------------------------------------------------- -->
                    {:else if d.avg*100<0}

                        <em>Trend</em> harga mingguan terkini di Malaysia menunjukkan
                        <span class="text-blue font-semibold" style="color:{Config.colors.blue}">penurunan</span> harga purata sebanyak
                        <span class="text-blue font-semibold" style="color:{Config.colors.blue}">{f1(d.avg * 100)}%</span>
                        untuk pelbagai barangan.


                        <!--
                            negeri
                        -->

                        {#if perubahanLokasiMinNegeri.length}
                            <span class="capitalize" style="text-transform: capitalize;">
                                {#if perubahanLokasiMinNegeri.length > 1}
                                    <span class="text-blue font-semibold" style="color:{Config.colors.blue}">
                                        {perubahanLokasiMinNegeri.slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                                        dan {perubahanLokasiMinNegeri.slice(-1).map(d=>capitalizeText(d.label))}
                                    </span>
                                {:else}
                                    <span class="text-blue font-semibold" style="color:{Config.colors.blue}">
                                        {perubahanLokasiMinNegeri.map(d=>capitalizeText(d.label))}
                                    </span>
                                {/if}
                            </span>
                            mengalami
                            {d3.shuffle(['penurunan','pengurangan'])[0]}
                            {d3.shuffle(['paling ketara ','paling besar','paling banyak'])[0]}
                            sehingga
                            <span class="text-blue font-semibold" style="color:{Config.colors.blue}">{f1(perubahanLokasiMin*100)}%</span>.

                        {/if}


                        <!--
                            group
                        -->

                        {#if perubahanBarangTurun.length}

                            Kategori barang seperti

                            {#if perubahanBarangTurun.length>1}
                                {perubahanBarangTurun.sort(d3comparator().order(d3.ascending,d=>d['avg'] )).slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')},
                                dan
                                {perubahanBarangTurun.sort(d3comparator().order(d3.ascending,d=>d['avg'] )).slice(-1).map(d=>capitalizeText(d.label)).join(', ')}
                            {:else}
                                {perubahanBarangTurun.map(d=>capitalizeText(d.label)).join(', ')}
                            {/if}

                            menyumbang kepada penurunan harga barang.

                        {/if}

                    {/if}
                {/each}








                <!--
                    purata_negara_perc_diff
                -->
                <div class="py-4">


                    Harga purata barangan di
                    <span class="capitalize" style="text-transform: capitalize;">
                        {@html perubahanLokasi.filter(d=>(d['purata_negara_perc_diff']*100)<0).sort(d3comparator().order(d3.ascending,d=>d['purata_negara_perc_diff'] )).slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                    </span>
                    dan
                    <span class="capitalize" style="text-transform: capitalize;">
                        {@html perubahanLokasi.filter(d=>(d['purata_negara_perc_diff']*100)<0).sort(d3comparator().order(d3.ascending,d=>d['purata_negara_perc_diff'] )).slice(-1).map(d=>capitalizeText(d.label))}
                    </span>

                    {d3.shuffle([
                        'di bawah harga purata seluruh negara.',
                        'agak murah berbanding negeri lain.',
                    ])[0]}

                    Manakala harga purata barangan di
                    <span class="capitalize" style="text-transform: capitalize;">
                        {@html perubahanLokasi.filter(d=>+f2(d['purata_negara_perc_diff']*100)>0).sort(d3comparator().order(d3.descending,d=>d['purata_negara_perc_diff'] )).slice(0,-1).map(d=>capitalizeText(d.label)).join(', ')}
                    </span>
                    dan
                    <span class="capitalize" style="text-transform: capitalize;">
                        {@html perubahanLokasi.filter(d=>+f2(d['purata_negara_perc_diff']*100)>0).sort(d3comparator().order(d3.descending,d=>d['purata_negara_perc_diff'] )).slice(-1).map(d=>capitalizeText(d.label))}
                    </span>

                    {d3.shuffle([
                        'melebihi harga purata seluruh negara.',
                        'agak mahal berbanding negeri lain.',
                    ])[0]}

                </div>




                {#if data.stats_harga_turun_negeri && data.stats_harga_turun_negeri.length<16}
                    * Hanya negeri yang mempunyai maklumat harga pada
                    {@html labelDateRange(data.maxWeekDate, data.maxDate) }
                    sahaja yang disenaraikan
                {/if}




            </Card.Description>


        </Card.Header>
    </Card.Root>



</div>
<div class="flex items-stretch flex-wrap gap-1 p-1 pb-0">


    <!-- page title -->


    <Card.Root class="flex-1 shadow-sm min-w-[320px]">
        <div class="flex items-center flex-wrap gap-2 p-1">


            <Card.Header class="flex-1 min-w-[300px]">
                <Card.Title tag="h2" class="text-left text-3xl">

                    {#each data['weekly_prev_perc_diff'] as d}
                        {#if +f1(d.avg*100)==0 }
                            <span class="text-green" style="color:green">
                                Harga barang kekal
                            </span>
                        {:else if d.avg>0 }
                            <span class="text-red" style="color:{Config.colors.red}">
                                Harga barang naik
                            </span>
                        {:else}
                            <span class="text-blue" style="color:{Config.colors.blue}">
                                Harga barang turun
                            </span>
                        {/if}
                        
                    {/each}


                </Card.Title>
                <Card.Description>


                    {#each data['weekly_prev_perc_diff'] as d}
                        {#if +f1(d.avg*100)==0 }
                            Purata harga barang seluruh negara
                            kekal dengan perubahan
                            {f1(d.avg * 100)}
                            peratus
                        {:else if d.avg>0 }
                            Terdapat kenaikan harga purata barang seluruh negara
                            sebanyak
                            +{f1(d.avg * 100)}
                            peratus
                        {:else}
                            Terdapat penurunan harga purata barang seluruh negara
                            sebanyak
                            {f1(d.avg * 100)}
                            peratus
                        {/if}

                        untuk
                        {@html labelDateRange( data.maxWeekDate, data.maxDate) }
                        berbanding dengan minggu sebelum.

                        <!-- <p class="text-xs mt-2">* Pengiraan perubahan harga untuk barangan yang disenaraikan sahaja</p> -->

                    {/each}




                </Card.Description>

            </Card.Header>


            <Card.Header class="flex-1 text-center min-w-[200px]">

                <!--
                    weekly_prev_perc_diff
                -->
                <Card.Title>

                    <a class="anchor" style="scroll-margin-top: 40px;" name="perubahan_harga">
                        {#each data['weekly_prev_perc_diff'] as d}

                            {#if d.date==data.maxWeekDate}

                                <div class="text-[200%]">
                                    {#if +f1(d.avg*100)==0 }
                                        <span class="text-green" style="color:green">{f1(d.avg*100)}%</span>
                                    {:else if d.avg>0 }
                                        <span class="text-red" style="color:{Config.colors.red}">+{f1(d.avg*100)}%</span>
                                    {:else}
                                        <span class="text-blue" style="color:{Config.colors.blue}">{f1(d.avg*100)}%</span>
                                    {/if}
                                </div>


                                {#if +f1(d.avg*100)==0 }
                                    <span class="text-green" style="color:{Config.colors.green}">Harga Purata Kekal</span>
                                {:else if d.avg>0 }
                                    <span class="text-red" style="color:{Config.colors.red}">Harga Purata Naik</span>
                                {:else}
                                    <span class="text-blue" style="color:{Config.colors.blue}">Harga Purata Turun</span>
                                {/if}

                            {/if}

                            berbanding minggu lepas

                        {/each}
                    </a>

                </Card.Title>
            </Card.Header>

        </div>
    </Card.Root>


</div>




<div class="flex items-stretch flex-wrap gap-1 p-1 pb-0">

    <!--
        perubahan harga by negeri
    -->

    <Card.Root class="flex-1 shadow-sm min-w-[320px]">

        <Card.Header>
            <Card.Title tag="h2">
                <a class="anchor" style="scroll-margin-top: 40px;" name="perubahan_harga_mengikut_negeri"> </a>

                Kadar perubahan harga barang mengikut negeri

            </Card.Title>
            <Card.Description >

                Peratus perubahan harga barang mengikut negeri
                pada
                {@html labelDateRange(data.maxWeekDate, data.maxDate) }
                berbanding minggu sebelumnya.

            </Card.Description>

        </Card.Header>


        <Card.Content>
            {#if perubahanLokasi && perubahanLokasi.length > 0}
                <Table.Root class="">
                    <Table.Caption>
                        {#if data.stats_harga_turun_negeri && data.stats_harga_turun_negeri.length<16}
                            <Card.Description class="mt-4">
                                * Hanya negeri yang mempunyai maklumat harga pada
                                {@html labelDateRange(data.maxWeekDate, data.maxDate) }
                                sahaja yang disenaraikan
                            </Card.Description>
                        {/if}
                    </Table.Caption>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head class="align-bottom">Negeri</Table.Head>
                            <Table.Head class="align-bottom text-center">
                                <button on:click={()=>{
                                    sortLokasi.by = 'purata_negara_perc_diff';
                                    sortLokasi.order = sortLokasi.order=='asc'?'desc':'asc';
                                    perubahanLokasi = perubahanLokasi;
                                }}>
                                    Perbezaan Dengan<br>Harga Purata
                                    <span class="whitespace-nowrap">
                                        Negara
                                        {#if sortLokasi.by=='purata_negara_perc_diff'}
                                            {#if sortLokasi.order=='asc'}
                                                <span class="text-xs text-[#427FED]">↓</span>
                                            {:else}
                                                <span class="text-xs text-[#d72222]">↑</span>
                                            {/if}
                                        {/if}
                                    </span>
                                </button>
                            </Table.Head>
                            <Table.Head class="align-bottom text-center max-w-[200px]">
                                <button on:click={()=>{
                                    sortLokasi.by = 'avg';
                                    sortLokasi.order = sortLokasi.order=='asc'?'desc':'asc';
                                    perubahanLokasi = perubahanLokasi;
                                }}>
                                    <em>Trend</em> Perubahan<br>Harga
                                    <span class="whitespace-nowrap">
                                        Mingguan
                                        {#if sortLokasi.by=='avg'}
                                            {#if sortLokasi.order=='asc'}
                                                <span class="text-xs text-[#427FED]">↓</span>
                                            {:else}
                                                <span class="text-xs text-[#d72222]">↑</span>
                                            {/if}
                                        {/if}
                                    </span>
                                </button>
                            </Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {#each perubahanLokasi as d}
                            {
                                @const _metric = 'purata_negara_perc_diff'
                            }
                            <Table.Row>
                                <Table.Cell class="font-medium">
                                    <address>
                                        <a href={ urlDebug(dbg, d.url) } class="underline text-[blue] hover:text-[purple] cursor-pointer">
                                            {d.label}
                                        </a>
                                    </address>
                                    <!-- {#if dbg}
                                        <div class="text-xs opacity-50">{d.url}</div>
                                    {/if} -->
                                </Table.Cell>

                                <Table.Cell class="text-center">
                                        {#if +f1(d[ _metric ]*100)==0 }
                                            <span class="text-green" style="color:{Config.colors.green}"></span>
                                        {:else if d[ _metric ]>0 }
                                            <span class="text-red" style="color:{Config.colors.red}">+{f1(d[ _metric ]*100)}%</span>
                                        {:else}
                                            <span class="text-blue" style="color:{Config.colors.blue}">{f1(d[ _metric ]*100)}%</span>
                                        {/if}

                                        {#if +f1(d[ _metric ]*100)==0 }
                                            <span class="text-green" style="color:{Config.colors.green}"></span>
                                        {:else if d[ _metric ]>0 }
                                            <span class="text-red whitespace-nowrap" style="color:{Config.colors.red}">Lebih Tinggi</span>
                                        {:else}
                                            <span class="text-blue whitespace-nowrap" style="color:{Config.colors.blue}">Lebih Rendah</span>
                                        {/if}
                                </Table.Cell>


                                <Table.Cell class="text-center">
                                        {#if +f1(d.avg*100)==0 }
                                            <span class="text-green" style="color:{Config.colors.green}">Kekal</span>
                                        {:else if d.avg>0 }
                                            <span class="text-red" style="color:{Config.colors.red}">Naik</span>
                                        {:else}
                                            <span class="text-blue" style="color:{Config.colors.blue}">Turun</span>
                                        {/if}

                                        {#if +f1(d.avg*100)==0 }
                                            <span class="text-green" style="color:{Config.colors.green}"></span>
                                        {:else if d.avg>0 }
                                            <span class="text-red" style="color:{Config.colors.red}">+{f1(d.avg*100)}%</span>
                                        {:else}
                                            <span class="text-blue" style="color:{Config.colors.blue}">{f1(d.avg*100)}%</span>
                                        {/if}
                                </Table.Cell>
                            </Table.Row>
                        {/each}
                    </Table.Body>
                </Table.Root>
            {:else}
                <TableSkeleton rows={16} columns={3} />
            {/if}

<!--

            {#if data.stats_harga_turun_negeri && data.stats_harga_turun_negeri.length<16}
                <Card.Description class="mt-4">
                    * Hanya negeri yang mempunyai maklumat harga pada
                    {@html labelDateRange(data.maxWeekDate, data.maxDate) }
                    sahaja yang disenaraikan
                </Card.Description>
            {/if} -->
        </Card.Content>


    </Card.Root>


    <!--
        perubahan harga by group
    -->
    <Card.Root class="flex-1 shadow-sm min-w-[320px]">
        <Card.Header>
            <Card.Title tag="h2">
                <a class="anchor" style="scroll-margin-top: 40px;" name="perubahan_harga_mengikut_kumpulan_barang"> </a>

                Kadar perubahan harga barang mengikut kumpulan barangan

            </Card.Title>
            <Card.Description>
                Peratus perubahan harga barang mengikut kumpulan barangan
                pada minggu
                {@html labelDateRange( data.maxWeekDate, data.maxDate) }
                berbanding minggu sebelumnya
            </Card.Description>

        </Card.Header>
        <Card.Content>


            <Table.Root class="">
                <Table.Header>
                    <Table.Row>
                        <Table.Head class="align-bottom">Kumpulan Barangan</Table.Head>
                        <Table.Head class="align-bottom text-center max-w-[200px]">
                            <button on:click={()=>{

                                sortBarang.by = 'avg';
                                sortBarang.order = sortBarang.order=='asc'?'desc':'asc';
                                perubahanBarang = perubahanBarang;

                            }}>
                                <em>Trend</em> Perubahan<br>Harga
                                <span class="whitespace-nowrap">
                                    Mingguan
                                    {#if sortBarang.by=='avg'}
                                        {#if sortBarang.order=='asc'}
                                            <span class="text-xs text-[#427FED]">↓</span>
                                        {:else}
                                            <span class="text-xs text-[#d72222]">↑</span>
                                        {/if}
                                    {/if}
                                </span>
                            </button>
                        </Table.Head>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <!--
                    {#each data['weekly_prev_perc_diff_group']
                        .map(d=>{
                            d.url = urlGroup(d.key);
                            d.label = d.key;
                            return d;
                        })
                        .sort(d3comparator().order(d3.ascending, d=>d.avg*100))
                        as d
                    }
                     -->
                    {#each perubahanBarang as d}
                        <Table.Row>
                            <Table.Cell class="font-medium">
                                <a href={ urlDebug(dbg, d.url) }  class="underline text-[blue] hover:text-[purple] cursor-pointer">
                                    {d.label}
                                </a>
                                <div class="text-xs mt-1">
                                    {(data['weekly_prev_perc_diff_category']||[]).filter(k=>k.item_group==d.key).map(d=>d.key).slice(0,5).join(', ')}
                                    {(data['weekly_prev_perc_diff_category']||[]).filter(k=>k.item_group==d.key).map(d=>d.key).length>5?'...':''}
                                </div>
                                <!-- {#if dbg}
                                    <div class="text-xs opacity-50">{d.url}</div>
                                {/if} -->

                            </Table.Cell>


                            <Table.Cell class="text-center">

                                {#if +f1(d.avg*100)==0 }
                                    <span class="text-green" style="color:{Config.colors.green}">Kekal</span>
                                {:else if d.avg>0 }
                                    <span class="text-red" style="color:{Config.colors.red}">Naik</span>
                                {:else}
                                    <span class="text-blue" style="color:{Config.colors.blue}">Turun</span>
                                {/if}

                                {#if +f1(d.avg*100)==0 }
                                    <span class="text-green" style="color:{Config.colors.green}"></span>
                                {:else if d.avg>0 }
                                    <span class="text-red" style="color:{Config.colors.red}">+{f1(d.avg*100)}%</span>
                                {:else}
                                    <span class="text-blue" style="color:{Config.colors.blue}">{f1(d.avg*100)}%</span>
                                {/if}
                            </Table.Cell>
                        </Table.Row>
                    {/each}
                </Table.Body>
            </Table.Root>

        </Card.Content>
    </Card.Root>



</div>







<div class="flex items-stretch flex-wrap gap-1 p-1 pb-0">


    <!--
        kedai murah
    -->
    <Card.Root class="flex-1 shadow-sm min-w-[320px]">
        <Card.Header>
            <Card.Title tag="h2">
                <a class="anchor" style="scroll-margin-top: 40px;" name="kedai_harga_murah"> </a>

                Kedai harga murah

            </Card.Title>
            <Card.Description>

                Senarai kedai
                dengan harga purata mingguan barang yang disenaraikan
                lebih rendah berbanding harga purata barang yang sama di
                seluruh negara
                pada
                {@html labelDateRange( data.maxWeekDate, data.maxDate) }.

            </Card.Description>

        </Card.Header>
        <Card.Content class="mx-0 px-0">
            {#if data.kedai_murah && data.kedai_murah.length > 0}
                <KedaiMurah
                    {data}
                    kedai_murah={
                        (data['kedai_murah']||[])
                            .map(d=>{
                                d.url = urlKedai(d.lookup_premise.premise, d.lookup_premise.premise_code);
                                return d;
                            })
                            .sort(d3comparator()
                                .order(d3.ascending, d=>d.diff)
                                .order(d3.descending, d=>d.item_count)
                            )
                    }
                    metric='diff'
                    peringkat='negara'
                    {width}
                    {dbg}
                />
            {:else}
                <KedaiMurahSkeleton count={8} {size} />
            {/if}

        </Card.Content>





<!--
        <Card.Content class="mx-1 px-1">
            <a href="/kedai">
                Carian nama kedai
            </a>

        </Card.Content> -->




    </Card.Root>


</div>

<!--
    harga paling murah
-->
<!--
<div class="p-1">


    <Card.Root class="shadow-sm">

        <Card.Header>
            <Card.Title tag="h2">
                <a class="anchor" style="scroll-margin-top: 40px;" name="harga_barang_paling_rendah">
                    Harga paling rendah
                </a>
            </Card.Title>
            <Card.Description>
                Senarai barangan dengan harga paling rendah
                pada
                {@html labelDateRange( data.maxWeekDate, data.maxDate) }
            </Card.Description>

        </Card.Header>

        <HargaPalingRendah  {data} {size}/>

    </Card.Root>

</div> -->




<!--
    harga purata barangan seluruh negara
-->
<div class="p-1">


    <Card.Root class="shadow-sm">

        <Card.Header>
            <Card.Title tag="h2">
                <a class="anchor" style="scroll-margin-top: 40px;" name="harga_barang_paling_rendah"> </a>

                Harga purata barangan seluruh negara

            </Card.Title>
            <Card.Description>
                Senarai barangan dengan harga purata seluruh negara
                pada
                {@html labelDateRange( data.maxWeekDate, data.maxDate) }
            </Card.Description>

        </Card.Header>

        <!-- Harga Purata -->
        {#if data._items_avg && data._items_avg.length > 0}
            <HargaPurata
                current_item={data._latest}
                barangan={data._items_avg}
                timeframe={data.maxWeekDate}
                dslmust={{}}
                {data}
                {size}
                {dbg}
            />
        {:else}
            <HargaPalingRendahSkeleton count={6} {size} />
        {/if}

    </Card.Root>

</div>




<!-- Buy Online and Online Stores sections removed -->

<!-- Pageview component removed -->
