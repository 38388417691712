<script>
    export let count = 6;
    export let size = 'md';
    
    import * as Card from "$lib/components/ui/card";
    import * as Skeleton from "$lib/components/ui/skeleton";
</script>

<Card.Content class="mx-1 px-1 pb-4">
    <!-- Section title skeleton -->
    <div class="flex justify-center items-center mb-6">
        <Skeleton.Root class="h-8 w-64 mb-2" />
    </div>
    
    <!-- Tabs skeleton -->
    <div class="flex justify-center items-center space-x-4 mb-8">
        {#each Array(3) as _, i}
            <Skeleton.Root class="h-10 w-28" />
        {/each}
    </div>
    
    <!-- Store cards skeleton -->
    <div class="flex justify-center items-stretch flex-wrap gap-3">
        {#each Array(count) as _, i}
            <div class="flex-1 min-w-[270px] max-w-[350px]">
                <Card.Root class="shadow-sm h-full">
                    <Card.Header class="pb-1">
                        <!-- Store logo placeholder -->
                        <div class="flex justify-center mb-2">
                            <Skeleton.Root class="h-16 w-24" />
                        </div>
                        <!-- Store name -->
                        <Skeleton.Root class="h-6 w-4/5 mx-auto mb-2" />
                        <!-- Location -->
                        <Skeleton.Root class="h-4 w-3/5 mx-auto" />
                    </Card.Header>
                    <Card.Content>
                        <!-- Stats -->
                        <div class="flex justify-between items-center mb-2">
                            <Skeleton.Root class="h-5 w-1/3" />
                            <Skeleton.Root class="h-5 w-1/3" />
                        </div>
                        <!-- Item count -->
                        <Skeleton.Root class="h-4 w-2/3 mb-3 mx-auto" />
                        <!-- Price difference -->
                        <div class="flex justify-center">
                            <Skeleton.Root class="h-7 w-1/2" />
                        </div>
                    </Card.Content>
                </Card.Root>
            </div>
        {/each}
    </div>
</Card.Content>