<script>
    export let count = 6;
    export let size = 'md';
    
    import * as Card from "$lib/components/ui/card";
    import * as Skeleton from "$lib/components/ui/skeleton";
</script>

<Card.Content class="mx-1 px-1 pb-4">
    <div class="flex justify-center items-center mb-4">
        <Skeleton.Root class="h-10 w-32" />
    </div>
    
    <!-- Search bar skeleton -->
    <div class="flex w-full max-w-sm items-center space-x-2 mb-6">
        <Skeleton.Root class="h-10 w-full" />
        <Skeleton.Root class="h-10 w-10" />
    </div>
    
    <!-- Group buttons skeleton -->
    <div class="flex flex-wrap gap-2 mb-4">
        {#each Array(5) as _, i}
            <Skeleton.Root class="h-8 w-24" />
        {/each}
    </div>
    
    <!-- Category buttons skeleton -->
    <div class="flex flex-wrap gap-2 mb-6">
        {#each Array(3) as _, i}
            <Skeleton.Root class="h-8 w-28" />
        {/each}
    </div>
    
    <!-- Item cards skeleton -->
    <div class="flex justify-center items-stretch flex-wrap gap-2">
        {#each Array(count) as _, i}
            <div class="flex-1 min-w-[270px] max-w-[350px]">
                <Card.Root class="h-full">
                    <Card.Header class="pb-2">
                        <Skeleton.Root class="h-6 w-4/5 mb-2" />
                        <Skeleton.Root class="h-4 w-1/2" />
                    </Card.Header>
                    <Card.Content class="p-2">
                        <div class="flex justify-between pb-1">
                            <Skeleton.Root class="h-8 w-1/3" />
                            <Skeleton.Root class="h-8 w-1/3" />
                        </div>
                        <Skeleton.Root class="h-4 w-full mb-2" />
                        <Skeleton.Root class="h-4 w-3/4" />
                    </Card.Content>
                </Card.Root>
            </div>
        {/each}
    </div>
</Card.Content>